import { CoreMenu } from "@core/types";

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: "apps",
    type: "section",
    title: "Users",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "list",
        title: "Trainee",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "user",
        url: "apps/user/user-list",
      },
      {
        id: "coaches",
        title: "Coaches",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "user",
        url: "apps/coach/coaches-list",
      },
      // {
      //   id: "view",
      //   title: "View",
      //   translate: "MENU.APPS.USER.VIEW",
      //   type: "item",
      //   icon: "circle",
      //   url: "apps/user/user-view",
      // },
      // {
      //   id: "edit",
      //   title: "Edit",
      //   translate: "MENU.APPS.USER.EDIT",
      //   type: "item",
      //   icon: "circle",
      //   url: "apps/user/user-edit",
      // },
    ],
  },
  {
    id: "apps",
    type: "section",
    title: "Exercises",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "list",
        title: "Exercise Categories",
        translate: "Exercise Categories",
        type: "item",
        icon: "category",
        url: "apps/category/category-list",
      },
      {
        id: "list",
        title: "Exercises",
        translate: "Exercises",
        type: "item",
        icon: "excercise",
        url: "apps/exercise/exercise-list",
      },
    
    ],
  },
  {
    id: "nutrition",
    type: "section",
    title: "Nutritions",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "list",
        title: "Nutrition Categories",
        translate: "Nutrition Categories",
        type: "item",
        icon: "category",
        url: "apps/nutrition-category/nutrition-category-list",
      },
      {
        id: "list",
        title: "Nutritions",
        translate: "Nutritions",
        type: "item",
        icon: "nutrition",
        url: "apps/nutrition/nutrition-list",
      },
    
    ],
  },
  {
    id: "subscriptions",
    type: "section",
    title: "Subscription",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "list",
        title: "Plans",
        translate: "Plans",
        type: "item",
        icon: "dollar-sign",
        url: "apps/subscription/subscription-list",
      },
      {
        id: "list",
        title: "Coupons",
        translate: "Coupons",
        type: "item",
        icon: "dollar-sign",
        url: "apps/coupons/coupons-list",
      },
    ],
  },
];
